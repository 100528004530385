.custom-loader{
  margin-top: 2rem;
  width: 100%;
  padding: .5rem;
  overflow: hidden;
  position: relative;
  border: 2px solid #1d1d1d;
}
.custom-loader>div{
  width: 50%;
  height: 2rem;
  background-color: #1d1d1d;
}

.custom-loader::before{
  top: 50%;
  left: 50%;
  color: #fff;
  display: block;
  font-weight: 800;
  font-size: 1.5rem;
  font-style: italic;
  position: absolute;
  letter-spacing: .25rem;
  content: attr(data-text);
  mix-blend-mode: difference;
  font-family: FiraGO, sans-serif;
  transform: translate(-50%, -50%);
}
